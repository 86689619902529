import {
  checkPublicEmailDomains,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  prepareDynamicBlock,
  setProtocol,
} from '@webplatform/frontkit/dist/helpers';

export {
  checkPublicEmailDomains,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
  prepareDynamicBlock,
  setProtocol,
};
