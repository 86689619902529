
import {
  computed,
  defineComponent,
  useContext,
  useStore,
} from '@nuxtjs/composition-api';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppTopbar from '@/components/AppTopbar.vue';
import { usePagePreloader } from '@/composables';
import AppUpButton from '@/components/AppUpButton.vue';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    AppUpButton,
    AppTopbar,
  },
  setup() {
    const { route } = useContext();
    const store = useStore();
    const pagePreloader = usePagePreloader();

    const isHomepage = computed(() => route.value?.fullPath === '/');

    const menuTop = store.getters['menus/menuTop'];
    const menuMain = store.getters['menus/menuMain'];
    const menuFooter = store.getters['menus/menuFooter'];
    const menuBottom = store.getters['menus/menuBottom'];

    return {
      isHomepage,
      menuTop,
      menuMain,
      menuFooter,
      menuBottom,
      pagePreloader,
    };
  },
  // @ts-ignore
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const { googleGtagId, baseUrl } = this.$config;
    const scripts = [];

    if (googleGtagId)
      scripts.push({
        src: `https://www.googletagmanager.com/gtag/js?id=${googleGtagId}`,
        async: true,
      });

    return {
      title: 'Mododomus',
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: 'theme-mdm',
      },
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${baseUrl}/images/mododomus-og.png`,
        },
        { hid: 'og:image:width', property: 'og:image:width', content: '240' },
        { hid: 'og:image:height', property: 'og:image:height', content: '240' },
        ...i18nHead.meta,
      ],
      link: [...i18nHead.link],
      script: scripts,
    };
  },
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name: 'Mododomus',
      url: 'https://mododomus.cy',
      logo: 'https://cdn0.it4profit.com/s3/cms/documents/37/be/37be3698db799dcf1ba4d13816a62f47/logo_1200x1200_transaprent.webp',
      email: 'welcome@mododomus.cy',
      address: [
        {
          '@type': 'PostalAddress',
          streetAddress: '12 Pindarou Str., Industrial Area Aradipou',
          addressLocality: 'Larnaca',
          postalCode: '7100',
          addressCountry: 'CY',
          telephone: '+357 77 788 459',
        },
        {
          '@type': 'PostalAddress',
          streetAddress: 'Iapetou 1, Agios Athanasios',
          addressLocality: 'Limassol',
          postalCode: '4101',
          addressCountry: 'CY',
          telephone: '+357 99 976 691',
        },
      ],
      openingHours: 'Mo-Fr 09:00-18:00',
    };
  },
});
